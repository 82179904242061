import createReducer from '../createReducer'
import {
  DETAILS,
  LIST
} from './actions'

const details = {
    started_at: '',
    scan_id: '',
    ended_at: '',
    last_updater_heartbeat: '',
    information: {},
    issues: {},
    percentage: 0
}


export const initialState = {
  details,
  list: []
}

const reducer = createReducer(initialState, {
  [DETAILS]: (state, action) => ({
    ...state,
    details: action.payload
  }),
  [LIST]: (state, action) => ({
    ...state,
    list: action.payload
  })
})

export default reducer
