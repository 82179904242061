import axios from 'axios'
import { time } from "../../utils/parserURL"
import {
  LIST,
  DETAILS
} from './actions'

const baseUrl = process.env.REACT_APP_BASE_URL_API


const setList = (domain) => async (dispatch) => {
  try {
    const success = await axios.post(
        `${baseUrl}/list.json`,
        new URLSearchParams({
            'domain': domain
        })
    );
    const payload = success.data.ids.map(id => {
      const date = new Date(id.started_at);
      const { month, day, hour, min } = time(date)
      return {
        scan_id: id.scan_id,
        date: `${day}/${month}`,
        time: `${hour}:${min}`
      }
    })
    dispatch({ type: LIST, payload })
  } catch (error) {
    console.log('error', error)
  }
}

const setDetails = (scan_id) => async (dispatch) => {
  try {
    const success = await axios.get(
        `${baseUrl}/details.json?scan-id=${scan_id}`
    );
    dispatch({ type: DETAILS, payload: success.data.scan })
  } catch (error) {
    console.log('error', error)
  }
}

const setCleanList = (scan_id) => async (dispatch) => {
  dispatch({ type: LIST, payload: [] })
}

const setCleanDetails = (scan_id) => async (dispatch) => {
  dispatch({ type: DETAILS, payload: {} })
}

export {
  setCleanDetails,
  setCleanList,
  setList,
  setDetails
}
