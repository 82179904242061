import { Link } from "react-router-dom";
import cybersecHorizontalLogoRedSvg from "../../images/cybersec-horizontal-logo-red.svg";
import akadLogoRedSvg from "../../images/akad-logo-red.svg";
import "./index.scss";

const block = "main-header";
const MainHeader = () => {
  return (
    <div className={block}>
      <div className={`${block}__container container`}>
        <Link to="https://www.akadseguros.com.br/cyber/" target="_blank">
          <img
            src={cybersecHorizontalLogoRedSvg}
            alt="Logotipo CyberSec Scan de vulnerabilidade"
          />
        </Link>
        <a href="https://akadseguros.com.br/" target="_blank" rel="noreferrer">
          <img src={akadLogoRedSvg} alt="Logotipo Akad Seguros" />
        </a>
      </div>
    </div>
  );
};

export default MainHeader;
