const addProtocol = (urlString) => {
  if(!/^(?:f|ht)tps?:\//.test(urlString)) {
    urlString = 'https://'+urlString
  }

  return urlString
}

const validationURL = (urlString) => {
  urlString = urlString.trim(); // removes whitespace from both sides
  if (!urlString) // is Empty
    return false

  const urlPattern = new RegExp('[(http(s)?)://(www\\.)?a-zA-Z0-9@:%._\\+~#=]{2,256}\\.[a-z]{2,6}\\b([-a-zA-Z0-9@:%_\\+.~#?&//=]*)','i'); // regex validate

  urlString = addProtocol(urlString) //Add protocol if not exists

  if (!urlPattern.test(urlString))
    return false

  return true
}

export default function parserURL(urlString) {
  try {
    urlString = urlString.trim(); // removes whitespace from both sides
    if (!urlString)
      throw new Error('Informe um domínio para ser verificado');

    if (!validationURL(urlString))
      throw new Error('Informe um domínio válido. Ex.: meusite.com.br');

    urlString = addProtocol(urlString) //Add protocol if not exists
    const url = new URL(urlString);
    return url;
  } catch (error) {
    return error;  // => TypeError, "Failed to construct URL: Invalid URL"
  }
}

const time = (value) => {
  const year = value.getYear();
  let month = value.getMonth()+1;
  let day = value.getDate();
  let hour = value.getHours();
  let min = value.getMinutes();
  if (min < 10) {
    min = '0' + min;
  }
  if (hour < 10) {
    hour = '0' + hour;
  }
  if (day < 10) {
    day = '0' + day;
  }
  if (month < 10) {
    month = '0' + month;
  }
  return {
    year, month, day, hour, min
  }
}

export { addProtocol, validationURL, time };
