import React from "react";
import { Provider } from 'react-redux'
import ReactDOM from "react-dom/client";
import { Details, Home, Results} from "./pages";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Store from './store'
import "./index.scss";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={Store}>
    <React.StrictMode>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/resultados" element={<Results />}>
            <Route path=":domain" element={<Results />} />
          </Route>
          <Route path="/detalhes" element={<Details />}>
            <Route path=":domain&:scan_id" element={<Details />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </React.StrictMode>
  </Provider>
);
