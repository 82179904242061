import { useCallback, useEffect, useState } from "react";
import { useSearchParams  } from "react-router-dom";
import { useDispatch } from 'react-redux'
import { time } from "../../utils/parserURL";
import { MainHeader, ScanHeader, SeveretiesCounter } from "../../components";
import chevronDownSvg from "../../images/chevron-down.svg";
import "./index.scss";

import {
  setCleanDetails,
  setDetails,
  useCyber
} from '../../store/cyber'

const block = "page-details";
function Details() {
  const [toogles, setToogles] = useState([]);
  const [searchParams] = useSearchParams();
  const { details } = useCyber();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setCleanDetails())
    dispatch(setDetails(searchParams.get("scan_id")))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const severityChunk = useCallback((severity) => {
    return (
      <div
        className={`${block}__severity-chunk ${block}__severity-chunk--${severity}`}
      >
        {severity}
      </div>
    );
  }, []);

  const doToogle = (valueIndex, issueIndex) => {
    const values = toogles
    values[valueIndex][issueIndex] = !values[valueIndex][issueIndex]
    setToogles([...values])
  }

  const takeScanDate = () => {
    const date = new Date(details.ended_at);
    const { year, month, day, hour, min } = time(date)
    if (year) {
      return `${day}/${month}/${year} ${hour}:${min} GMT-3`
    } else {
      return 'GMT-3'
    }
  }

  const takeClass = (valueIndex, issueIndex) => {
    if (toogles.length) {
      return toogles[valueIndex]?.[issueIndex] ? 'page-details__accordion-solution-open' : 'page-details__accordion-solution-close'
    }
    return 'page-details__accordion-solution-close'
  }

  const takeValues = () => {
    let arr = []
    if (details && details.issues) {
      const keys = Object.keys(details.issues)
      let values = Object.values(details.issues).map(value => value.issues)
      arr = keys.map((key, index) => {
        return {
          title: key,
          issues: values[index]
        }
      })
    }
    return arr
  }

  const takeTitle = (title) => {
    switch (title) {
      case 'information_sensitive':
        return 'Sensitive Information'
      case 'headers':
        return 'Headers'
      case 'cms':
        return 'CMS'
      case 'gain_information':
        return 'Gain Information'
      default:
        return title
    }
  }

  const takeSevereties = () => {
    let values = takeValues()
    if (details && values.length) {
      if (values.length > 1) {
        values = takeValues().reduce((previousValue, currentValue) => {
          const current = currentValue?.issues?.map(
            (issue, issueIndex) => {
              return issue.severity
            }
          )

          let previous = previousValue
          if (!Array.isArray(previous)) {
            previous = previousValue?.issues?.map(
              (issue, issueIndex) => {
                return issue.severity
              }
            )
          }
          return [ ...previous, ...current ]
        })
      } else {
        values = values[0].issues?.map(value => {
          return value.severity
        })
      }

      const low = values.filter(value => value === 'low').length
      const medium = values.filter(value => value === 'medium').length
      const high = values.filter(value => value === 'high').length
      const critical = values.filter(value => value === 'critical').length
      return {
        low,
        medium,
        high,
        critical
      }
    }
    return {
      low: 0,
      medium: 0,
      high: 0,
      critical: 0
    }
  }

  useEffect(() => {
    if (takeValues().length) {
      const toogles = takeValues().map((value, index) => {
        return value.issues.map((issue, issueIndex) => {
          return false
        })
      })
      setToogles(toogles)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [details])

  return (
    <div className={block}>
      <MainHeader />

      <div className="container">
        <ScanHeader
          score={details.percentage}
          domain={searchParams.get("domain")}
          cdn={details?.information?.cdn || ''}
          scanDate={takeScanDate()}
        />

        <SeveretiesCounter severeties={takeSevereties()}/>

        {details && takeValues().length ?
          takeValues().map((value, valueIndex) => {
            return <div className={`${block}__accordion`} key={valueIndex}>
              <header className={`${block}__accordion-header`}>
                <div className={`${block}__accordion-title`}>{takeTitle(value.title)}</div>
                <div className={`${block}__accordion-labels`}>
                  <div className={`${block}__accordion-label`}>#</div>
                  <div className={`${block}__accordion-label`}>Risco</div>
                  <div className={`${block}__accordion-label`}>Gravidade</div>
                </div>
              </header>
              {value.issues.map(
                (issue, issueIndex) => {
                  return (
                    <div className={`${block}__accordion-item`} key={issueIndex}>
                      <div className={`${block}__accordion-index`}>
                        {issueIndex + 1}
                      </div>
                      <div className={`${block}__accordion-content`}>
                        <div className={`${block}__accordion-headline`}>
                          {issue.name}
                        </div>
                        <div className={`${block}__accordion-details`}>
                          {issue.description}
                        </div>
                        <div className={takeClass(valueIndex, issueIndex)}>
                          <div className={`${block}__accordion-solution`}>
                            Solução
                          </div>
                          <div className={`${block}__accordion-details`}>
                            {issue.remediation}
                          </div>
                        </div>
                      </div>
                      <div className={`${block}__accordion-severity`}>
                        {severityChunk(issue.severity)}
                      </div>
                      <div className={`${block}__accordion-toggle`}>
                        <img
                          className={`${block}__accordion-toggle-icon`}
                          src={chevronDownSvg}
                          alt=""
                          onClick={() => doToogle(valueIndex, issueIndex)}
                        />
                      </div>
                    </div>
                  );
                }
              )}
            </div>
          })
          : <></>
        }
      </div>
    </div>
  );
}

export default Details;
