import { useEffect, useRef, useState } from 'react';
import { MdDomain } from 'react-icons/md';
import './index.scss'
const block = 'scan-header'
const ScanHeader = ({
  score,
  domain,
  cdn,
  scanDate
}) => {
  const rulerRef = useRef();
  const [ pointerStyle, setPointerStyle ] = useState({})
  const [ lineStyle, setLineStyle ] = useState({})

  useEffect(() => {
    const pixel = rulerRef.current.offsetWidth * score / 100 - 27;

    setPointerStyle({
      backgroundColor: takePointerColor(score),
      transform: `translateX(${pixel}px)`
    })

    setLineStyle({
      transform: `translateX(${rulerRef.current.offsetWidth * score / 100 - 1}px)`
    })
  }, [score])

  const takePointerColor = (score) => {
    if (score > 90) {
      return '#bf0000'
    }

    if (score > 80) {
      return '#e70000'
    }

    if (score > 70) {
      return '#ff0000'
    }

    if (score > 60) {
      return '#ff5600'
    }

    if (score > 50) {
      return '#ff8d00'
    }

    if (score > 40) {
      return '#ffb600'
    }

    if (score > 30) {
      return '#ffcc00'
    }

    if (score >20) {
      return '#b7db00'
    }

    if (score > 10) {
      return '#75e800'
    }

    if (score > 0) {
      return '#40d600'
    }

    return '#00bf00'
  }

  const doPrint = () => {
    window.print();
  }

  return (
    <div className={block}>
      <div className={`${block}__header`}>
      <div className={`${block}__header-title`}>
          <MdDomain />
          <h2>{domain}</h2>
        </div>
        <div className={`${block}__header-infos`}>
          <div className={`${block}__header-infos-item`}><b>CDN</b>{cdn}</div>
          <div className={`${block}__header-infos-item`}><b>Data da Varredura</b>{scanDate}</div>
        </div>
      </div>
      <h3 className={`${block}__title`}>Evolução do scan</h3>
      <div className={`${block}__content`}>
        <div className={`${block}__ruler-wrapper`}>
          <div className={`${block}__ruler`} ref={rulerRef}>
            <div className={`${block}__ruler-pointer`} style={pointerStyle}>{score ? score : 0}%</div>
            <div className={`${block}__ruler-line`} style={lineStyle} />
            <div className={`${block}__ruler-legend`}>Porcentagem de conclusão</div>
          </div>
        </div>
        <button className={`${block}__button`} onClick={() => doPrint()}>Download do relatório</button>
      </div>
    </div>
  )

}

export default ScanHeader
