import './index.scss'
const block = 'severeties-counter'

const SeveretiesCounter = ({
  severeties
}) => {

  return (
    <div className={block}>
      <div className={`${block}__text`}>
        <p className={`${block}__title`}>Vulnerabilidades</p>
        <p className={`${block}__description`}>Vulnerabilidades que afetam os ativos associados a esta organização.</p>
      </div>
      <div className={`${block}__content`}>
        <div className={`${block}__counter`}>
          <div className={`${block}__color-stick ${block}__color-stick--dark-red`}/>
          <div className={`${block}__counter ${block}__counter--texts`}>
            <p className={`${block}__counter--texts texts--title`}>Crítico</p>
            <p className={`${block}__counter--texts texts--description`}>{severeties.critical}</p>
          </div>
        </div>
        <div className={`${block}__counter`}>
          <div className={`${block}__color-stick ${block}__color-stick--red`}/>
          <div className={`${block}__counter ${block}__counter--texts`}>
            <p className={`${block}__counter--texts texts--title`}>Alto</p>
            <p className={`${block}__counter--texts texts--description`}>{severeties.high}</p>
          </div>
        </div>
        <div className={`${block}__counter`}>
          <div className={`${block}__color-stick ${block}__color-stick--yellow`}/>
          <div className={`${block}__counter ${block}__counter--texts`}>
            <p className={`${block}__counter--texts texts--title`}>Médio</p>
            <p className={`${block}__counter--texts texts--description`}>{severeties.medium}</p>
          </div>
        </div>
        <div className={`${block}__counter`}>
          <div className={`${block}__color-stick ${block}__color-stick--green`}/>
          <div className={`${block}__counter ${block}__counter--texts`}>
            <p className={`${block}__counter--texts texts--title`}>Baixo</p>
            <p className={`${block}__counter--texts texts--description`}>{severeties.low}</p>
          </div>
        </div>
      </div>
    </div>
  )

}

export default SeveretiesCounter
