import { useEffect, useState } from "react";
import { useSearchParams  } from "react-router-dom";
import { useDispatch } from 'react-redux'
import { useNavigate } from "react-router-dom";
import parserURL from "../../utils/parserURL";
import { Input, MainHeader } from "../../components";
import "./index.scss";


import {
  setCleanList,
  setList,
  useCyber
} from '../../store/cyber'

const block = "page-results";
function Results() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [inputDomain, setInputDomain] = useState('');
  const [feedback, setFeedback] = useState();
  const [results, setResults] = useState();
  const { list } = useCyber();

  useEffect(() => {
    dispatch(setCleanList())
    setInputDomain(searchParams.get("domain"))
    dispatch(setList(searchParams.get("domain")))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    doSeparateByMonth()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [list])

  const doSeparateByMonth = () => {
    let months = [];
    let visitedsMonths = [];
    list.forEach(id => {
      const month = id.date.slice(3)
      if (visitedsMonths.find(m => m === month) === undefined) {
        visitedsMonths.push(month)
        const equals = list.filter(id => id.date.slice(3) === month)
        months.push(equals)
      }
    })
    setResults(months)
  }

  const takeMonth = (month) => {
    let selected = ''
    switch (month) {
      case '01':
        selected = 'Janeiro';
        break;
      case '02':
        selected = 'Fevereiro';
        break;
      case '03':
        selected = 'Março';
        break;
      case '04':
        selected = 'Abril';
        break;
      case '05':
        selected = 'Maio';
        break;
      case '06':
        selected = 'Junho';
        break;
      case '07':
        selected = 'Julho';
        break;
      case '08':
        selected = 'Agosto';
        break;
      case '09':
        selected = 'Setembro';
        break;
      case '10':
        selected = 'Outubro';
        break;
      case '11':
        selected = 'Novembro';
        break;
      case '12':
        selected = 'Dezembro';
        break;
      default:
        return selected
    }
    return selected
  }

  const doSelectOcorrency = (scan_id) => {
    navigate(`/detalhes?domain=${inputDomain}&scan_id=${scan_id}`);
  }

  const doHandleSubmit = () => {
    dispatch(setList(inputDomain))
    const url = parserURL(inputDomain);
    if (!url.message) {
      setFeedback();
      setInputDomain(url.hostname)
      setSearchParams({ domain: inputDomain });
    } else {
      setFeedback(url.message);
    }
  }

  const doHandleKeyPress = (e) => {
    if (e.keyCode === 13) doHandleSubmit();
  }

  return (
    <div className={block}>
      <MainHeader />
      <div className={`${block}__content`}>
        <div className="form-scan">
          <Input
            placeholder="example.com"
            value={inputDomain}
            onKeyDown={doHandleKeyPress}
            onChange={(e) => setInputDomain(e.target.value)}
            feedback={{ message: feedback }}
          />
          <button className={`${block}__button`} onClick={() => doHandleSubmit}>
            Escanear
          </button>
        </div>

        <div className={`${block}__info`}>{`Foram encontrados ${list.length} resultados`}</div>
        <div className={`${block}__results`}>
        {results && results.length
          ? results.map((ocorrencys, ocorrencysIndex) =>
            <div className={`${block}__result-group`} key={ocorrencysIndex}>
              <div className={`${block}__result-group-label`}>{takeMonth(ocorrencys[0]?.date?.slice(3))}</div>
                <div className={`${block}__result-group-items`}>
                {ocorrencys && ocorrencys.length && ocorrencys.map((ocorrency, ocorrencyIndex) =>
                  <div className={`${block}__result-item`} key={ocorrencyIndex} onClick={() => doSelectOcorrency(ocorrency.scan_id)}>
                    <div className={`${block}__result-item-headline`}>{ocorrency.date}</div>
                    <div className={`${block}__result-item-details`}>{ocorrency.time}</div>
                  </div>
                )}
                </div>
            </div>
          )
          : <></>
        }
        </div>
      </div>
    </div>
  );
}

export default Results;
