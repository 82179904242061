import './index.scss';

const block = "form-input";
function Input({
  feedback,
  label,
  value,
  onChange,
  onKeyDown,
  placeholder
}) {

  const feedbackClass = feedback?.message ? feedback.type === 'success' ? `${block}--success` : `${block}--error` : '';

  return (
    <div className={`${block} ${feedbackClass}`}>
      { label && <label className={`${block}__label`}>{label}</label> }
      <input className={`${block}__input`} value={value} onKeyDown={onKeyDown} onChange={onChange} placeholder={placeholder} />
      { feedback?.message && <span className={`${block}__feedback`}>{feedback.message}</span> }
    </div>
  )
}

export default Input;
