import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import parserURL from "../../utils/parserURL";
import { Input, MainHeader } from "../../components";
import cybersecVerticalLogoRedSvg from "../../images/cybersec-vertical-logo-red.svg";
import "./index.scss";

const block = "page-home";
function Home() {
  const [inputDomain, setInputDomain] = useState('');
  const [feedback, setFeedback] = useState();
  const navigate = useNavigate();

  const handleSubmit = useCallback(() => {
    const url = parserURL(inputDomain);
    if (!url.message) {
      setInputDomain(url.hostname)
      navigate(`/resultados?domain=${inputDomain}`);
    } else {
      setFeedback(url.message);
    }
  }, [navigate, inputDomain]);

  function handleKeyPress(e) {
    if (e.keyCode === 13) handleSubmit();
  }

  return (
    <div className={block}>
      <MainHeader />
      <div className={`${block}__content container`}>
        <img
          className={`${block}__logo`}
          src={cybersecVerticalLogoRedSvg}
          alt="Logotipo CyberSec Scan de vulnerabilidade"
        />
        <div className="form-scan">
          <Input
            label="Informe a URL do seu site"
            placeholder="example.com"
            onKeyDown={handleKeyPress}
            onChange={(e) => setInputDomain(e.target.value)}
            feedback={{ message: feedback }}
          />
          <button className={`${block}__button`} onClick={handleSubmit}>
            Escanear
          </button>
        </div>
      </div>
    </div>
  );
}

export default Home;
