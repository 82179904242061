import { useSelector } from 'react-redux'

const useCyber = () => {
  const { details } = useSelector((state) => ({
    details: state.cyber.details
  }))
  const { list } = useSelector((state) => ({
    list: state.cyber.list
  }))

  return {
    details,
    list
  }
}

export default useCyber
